body {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: none; 
}

.openbridge-name {
  color: white !important;
  vertical-align: middle;
}