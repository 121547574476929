.main-register {
  background-color: #494c4e;
  width: 500px;
  height: 800px;
  margin: 7em auto;
  padding-top: 30px;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.main-password {
  background-color: #494c4e;
  width: 400px;
  height: 500px;
  margin: 7em auto;
  padding-top: 30px;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}


.Register-Label {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.email {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.notes {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  color: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 14px;
}


.error {
  color: red;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 14px;

}

