/*
.dark-mode {
  background-color: var(--bg-color);
  color: var(--text-color);
  --background-color: #fefefe;
  --text-color: rgb(210, 210, 210); 
  --white-text-color: rgb(210, 210, 210); 
  --bg-color: #bfbebe;
}
*/

.light-mode {
  background-color: white;
  color: black;
  --background-color: white;
  --text-color: black;
  --white-text-color: white;
  --bg-color: white;
}

body {
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-size: 12px;
  --bs-body-font-family: "Gill Sans", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0c0d0d;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navtitle {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 32px;
  white-space: nowrap;
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.center {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.text-right {
  text-align: right;
}

.text-gray-600 {
  color: rgb(118, 116, 116)
}

/*
.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: red !important;
}
*/

.navbar-custom {
  background-color: #2b315e !important;
}

.callsignTable {
  width: auto;
  box-shadow: none;
}

.callsign {
  font-family: monospace;
}

table {
  width: 100%;
  border-radius: 5px;
  background-color: var(--bg-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
}

td {
  padding: 10px;
}

th {
  cursor: pointer;
  background-color: #2b315e !important;
  color: white;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: .3rem .3rem !important;
  font-size: .7rem !important;
  line-height: .5;
  border-radius: .2rem !important;
}

.btn-group-xxs>.btn,
.btn-xxs {
  padding: .08rem .08rem;
  font-size: .175rem;
  line-height: .5;
  border-radius: .2rem;
}

.btn-vsmall {
  padding: 0.3rem !important;
  font-size: .4rem !important;
  border-radius: 1rem !important;
}

.badge-sm {
  vertical-align: super;
  font-size: .4em;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 20px !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form_field_black {
  font-weight: 400;
  font-size: 16px;
  height: auto;
  padding: 15px;
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -moz-appearance: textfield;
  border-radius: 10px;
  border: 1px solid #052342;
  color: #0f0f0e;
  width: 250px;
  height: 50px;
  padding-left: 10px;
  font-size: 32px;
  text-align: center;

}

.pre-block {
  font-family: "Courier New", monospace;
  white-space: pre;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

pre {
  background-color: #e9ebef;
  min-height: 10vh;
}

.tooltip-heading {
  font-size: 1.2em;
  font-weight: 600;
}

form-label {
  color: #0c0d0d;
}

.form-select {
  font-size: 0.8rem;
  background-color: var(--background-color);
}

select {
  background-color: var(--background-color);
}

.label-right {
  text-align: right;
}

.ag-header-cell-filtered {
  background-color: #a3acf4 !important;
  color: #fff !important;
}

.ag-header-cell-filtered span {
  color: #fff !important;
}

.peer-config-modal {
  width: 90vw;    /* Occupy the 90% of the screen width */
  min-width: 50%
} 
