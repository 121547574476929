.input-container {
  display: flex;
  overflow: scroll;
  min-width: 50%;
  max-width: 75%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black
}

.input-container-input {
  width: 100%;
  min-width: 50%;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 14px;
  padding-left: 14px;
}

.input-callsign-tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid blue;
  border-radius: 5px;
  background-color: blue;
  white-space: nowrap;
  color: white;
}

.input-callsign-tag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}